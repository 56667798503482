import React from 'react'
import Head from '../../container/Head'
// import DepartmentContent from '../../Contents/DepartmentContent'

const Department = () => {
  return (
    <>
    <Head name={'Departments Details'}/>
    <div className="news-div" data-aos="fade-up">
    {/* <DepartmentContent/> */}
    </div>
    </>
    
  )
}

export default Department