import React from 'react'
import Head from"../container/Head"

const ErrorPage = () => {
  return (
    <>
    <Head name={"Nothing To Show"}/>
    ErrorPage</>
  )
}

export default ErrorPage